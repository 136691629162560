import { AxiosPromise } from 'axios';

import { userInstance } from '@app/adapter/axios';
import {
  RegisterUserSocial,
  RegisterUserSocialPayload,
  User,
  UserUpdate,
} from '@app/types/user';
import { getAuthorizationHeader } from '@app/utils/authorization';

/**
 * Register user use user-service
 * @param email
 * @param password
 * @returns
 */
export function register(payload: {
  customFields: {
    familyName: string;
    firstName: string;
    socialMediaName: string;
    usedSocialMediaPlatforms: string[];
  };
  email: string;
  password: string;
  phoneNumber: string;
}): AxiosPromise<{ id: string }> {
  return userInstance
    .post('/users', {
      ...payload,
      typeId: '000',
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Verify email via one-time token use user service
 * @param token
 * @returns
 */
export function verifyEmail(
  token: string
): AxiosPromise<{ token: string; userId: string }> {
  return userInstance
    .post(`/users/verify_email`, undefined, {
      headers: getAuthorizationHeader(token),
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 *
 * @param userId
 * @param token
 * @param fingerPrint
 * @returns
 */
export function getUser(
  userId: string,
  token: string,
  fingerprint: string,
  abortController?: AbortController
): AxiosPromise<User> {
  return userInstance.get(`/users/${userId}`, {
    headers: {
      'x-nb-fingerprint': fingerprint,
      ...getAuthorizationHeader(token),
    },
    signal: abortController?.signal,
  });
}

/**
 * send email to reset password
 * @param email - user email
 */
export function sendResetPasswordEmail(email: string): AxiosPromise<null> {
  return userInstance
    .post(`/users/send_reset_password_email`, { email })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * reset password
 * @param newPassword - new password
 * @param token - onetime token
 * @returns
 */
export function resetPassword(
  newPassword: string,
  token: string
): AxiosPromise<null> {
  return userInstance
    .post(
      `/users/reset_password`,
      { password: newPassword },
      {
        headers: getAuthorizationHeader(token),
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * reset password from account settings
 * @param userId
 * @param newPassword
 * @param token
 * @param fingerPrint
 */
export function resetPasswordFromSetting(
  userId: string,
  newPassword: string,
  token: string,
  fingerPrint: string
): AxiosPromise<null> {
  return userInstance
    .patch(
      `/users/${userId}`,
      { password: newPassword },
      {
        headers: {
          'x-nb-fingerprint': fingerPrint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get avatar upload url
 * @param userId
 * @param contentType
 * @param contentLength
 * @param token
 * @param fingerPrint
 */
export function getAvatarUploadUrl(
  userId: string,
  contentType: string,
  contentLength: number,
  token: string,
  fingerPrint: string
): AxiosPromise<string> {
  const params = new URLSearchParams();
  params.append('contentType', contentType);
  params.append('contentLength', contentLength.toString());

  return userInstance
    .get(`/users/${userId}/avatarUploadUrl?${params}`, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      throw new Error(error?.response?.data?.message);
    });
}

/**
 * Update user avatar
 */
export function updateAvatar(
  userId: string,
  avatar: string,
  token: string,
  fingerPrint: string
): AxiosPromise<{ id: string }> {
  return userInstance
    .patch(
      `/users/${userId}`,
      {
        avatar,
      },
      {
        headers: {
          'x-nb-fingerprint': fingerPrint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Update user details
 */
export function update(
  userId: string,
  token: string,
  fingerPrint: string,
  payload: UserUpdate
): AxiosPromise<User> {
  return userInstance
    .patch(`/users/${userId}`, payload, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Resend verification email to the user
 * @param userId - The ID of the user
 * @param token - The authorization token
 * @returns AxiosPromise<void>
 */
export function resendVerificationEmail(
  userId: string,
  token: string,
  fingerPrint: string
): AxiosPromise<void> {
  return userInstance
    .post(`/users/${userId}:sendVerificationEmail`, undefined, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Register user use user-service
 * @param snsUserTemp
 * @param socialType
 * @returns
 */
export function registerSocial(
  payload: RegisterUserSocialPayload,
  socialType?: string | null
): AxiosPromise<RegisterUserSocial> {
  return userInstance
    .post(`/users/${socialType}/register`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
